<script>
    import {
        Button,
        Card,
        Label,
        Modal,
        P,
        Select,
        Spinner,
        Textarea,
        Range,
    } from "flowbite-svelte";
    import { onMount } from "svelte";
    import {
    askAssistant,
        fetchFiles,
        fetchMe,
        getUser
    } from "../service/http_calls.js";
    import Compile from "./Compile.svelte";

    import { toast } from "@zerodevx/svelte-toast";
    import { marked } from "marked";
    import {
        ajust_response_modal_open,
        authenticated_user,
        components,
        current_user,
        discrepancy_modal_open,
        files_list,
        general_prompt,
        hallucination,
        prompt_edit_open,
        questions,
        selected_component,
        source_sentence_modal_open,
        source_sentence_questions_modal_open,
        sources,
    } from "../service/store.js";
    import FilesTable from "./FilesTable.svelte";
    const KUI_BASE_URL = import.meta.env.VITE_KUI_BASE_URL;
    let general_prompt_edit = false;

    onMount(async () => {
        let auth_resp = await fetchMe();
        if (!auth_resp) {
            window.location.href = KUI_BASE_URL + "/login";
        } else {
            authenticated_user.set(auth_resp);
            console.log("setting user " + auth_resp);
            let user = await getUser();
            current_user.set(user);
        }
        const resp = await fetchFiles("draft");
        files_list.set(resp);
    });

    let loading_compile = false;
    async function handleCompile() {
        loading_compile = true;
        let all_components = Object.keys($components)
            .map((key) =>
                $components[key].response
                    .map((item) => item.response)
                    .join(" "),
            )
            .join(" ");
        let resp = await askAssistant(
            $general_prompt,
            Object.keys($questions).reduce((acc, key) => {
                acc[key] = $questions[key].prompt;
                return acc;
            }, {}),
            "questions",
        );
        questions.update((prev) => {
            Object.keys(resp).forEach((key) => {
                if (prev[key] && resp[key].response !== undefined) {
                    prev[key].response = resp[key].response;
                } else {
                    console.error(
                        "Something is wrong in the components update!",
                    );
                }
            });
            return prev;
        });
        loading_compile = false;
        toast.push("Compiled successfully");
    }
    let narrative_voice = [
        { value: "First", name: "First Person" },
        { value: "Third", name: "Third Person" },
    ];
    let tense_list = [
        { name: "Past", value: "Past" },
        { name: "Present", value: "Present" },
        { name: "Future", value: "Future" },
    ];

    let current_length = 200;
    let current_tone = 2;
    let current_detail_level = 2;
    let current_vocabulary = 2;

    let detail_level_dict = {
        1: "High Level",
        2: "Mid Level",
        3: "Detailed",
    };
    let vocabulary_dict = {
        1: "Simple",
        2: "Mid (not too technical)",
        3: "Technical",
    };
    let tone_dict = {
        1: "Informal",
        2: "Not too formal",
        3: "Formal",
    };
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <FilesTable module="draft"></FilesTable>
            <div class="mb-10">
                <div class="mt-5 flex justify-end">
                    <Button
                        size="sm"
                        color="alternative"
                        class="mr-5"
                        on:click={() => {
                            general_prompt_edit = true;
                        }}>Add Instructions</Button
                    >
                    {#if loading_compile}
                        <Spinner></Spinner>
                    {:else}
                        <Button
                            color="purple"
                            size="sm"
                            on:click={handleCompile}>Submit</Button
                        >
                    {/if}
                </div>
                <Compile></Compile>
            </div>
        </Card>
    </div>
</div>

<Modal title="Add General Instructions" bind:open={general_prompt_edit} autoclose>
    <Textarea rows="5" bind:value={$general_prompt}></Textarea>
</Modal>

<Modal title="Add Instructions" bind:open={$prompt_edit_open} autoclose>
    <P size="lg" weight="bold">{$components[$selected_component].title}</P>
    <Textarea rows="5" bind:value={$components[$selected_component].prompt}
    ></Textarea>
</Modal>

<Modal title="Discrepancies" bind:open={$discrepancy_modal_open}>
    <P size="lg" weight="bold">{$components[$selected_component]?.title}</P>
    <div class="max-w-full prose">
        {@html marked($hallucination)}
    </div>
</Modal>
<Modal title="Source" bind:open={$source_sentence_modal_open}>
    <div class="max-w-full prose">
        {@html marked($sources)}
    </div>
</Modal>

<Modal title="Source" bind:open={$source_sentence_questions_modal_open}>
    <div class="max-w-full prose">
        {@html marked($sources)}
    </div>
</Modal>

<Modal title="Adjust Output" bind:open={$ajust_response_modal_open}>
    <div class="max-w-full">
        <Label class="mb-5">
            Narrative Voice
            <Select
                class="mt-2"
                items={narrative_voice}
                value={"Third"}
            />
        </Label>
        <Label class="mb-5">
            Tense
            <Select class="mt-2" items={tense_list} value={"Past"} />
        </Label>
        <Label>Length: {current_length}</Label>
        <Range
            min="100"
            max="500"
            bind:value={current_length}
            step={100}
            class="mb-5"
        />
        <Label>Tone: {tone_dict[current_tone]}</Label>
        <Range
            min="1"
            max="3"
            bind:value={current_tone}
            step={1}
            class="mb-5"
        />
        <Label>Detail Level: {detail_level_dict[current_detail_level]}</Label>
        <Range
            min="1"
            max="3"
            bind:value={current_detail_level}
            step={1}
            class="mb-5"
        />
        <Label>Vocabulary Level: {vocabulary_dict[current_vocabulary]}</Label>
        <Range min="1" max="3" bind:value={current_vocabulary} step={1} />
    </div>
</Modal>

<style>
    .bg-gray-100 {
        background-color: #f7fafc;
    }
</style>
