<script>
    import {
        Accordion,
        AccordionItem,
        Button,
        Card,
        P,
        Span,
        Spinner
    } from "flowbite-svelte";
    import { BadgeCheckOutline, FlagOutline } from "flowbite-svelte-icons";
    import { onMount } from "svelte";
    import {
        fetchFiles,
        fetchMe,
        getUser
    } from "../service/http_calls";
    import {
        authenticated_user,
        current_user,
        files_list
    } from "../service/store.js";
    import FilesTable from "./FilesTable.svelte";
    import { marked } from "marked";
    const KUI_BASE_URL = import.meta.env.VITE_KUI_BASE_URL;

    let loading_ingest = false;


    onMount(async () => {
        let auth_resp = await fetchMe();
        if (!auth_resp) {
            window.location.href = KUI_BASE_URL + "/login";
        } else {
            authenticated_user.set(auth_resp);

            let user = await getUser();
            current_user.set(user);
        }
        const resp = await fetchFiles("review");
        files_list.set(resp);
    });
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <FilesTable module="review"></FilesTable>
            <div class="flex justify-end my-5">
                {#if loading_ingest}
                    <Spinner></Spinner>
                {:else}
                    <Button size="sm" color="purple">Submit</Button>
                {/if}
            </div>
            <P size="lg" class="my-5"><Span gradient>Overall</Span></P>

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10">Coherence <BadgeCheckOutline color="green"></BadgeCheckOutline></span>
                    <P><strong>Coherent</strong></P>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header"  class="inline-flex items-center mr-10">Risk factors (if applicable) <FlagOutline color="red"></FlagOutline></span>
                    <p><strong>Risk Factors Detected:</strong></p>
<br/>
<p><strong>SIC Code Risk:</strong> The SIC code 87300 (Residential care activities for the elderly and disabled) is typically associated with care services, where R&D activities are uncommon. This could raise concerns about the eligibility of the R&D claim.</p>
<br/>
<p><strong>SIC Code Relevance:</strong> The SIC code does not align with the R&D activities in health informatics and operations research, which could lead to questions about the appropriateness of the claim.</p>
<br/>
<p><strong>Competent Professionals:</strong> Due to the SIC code, there may be a concern that the company lacks professionals with expertise in genetic algorithms and health informatics. Further details on Competent Professional profiles may be required.</p>
<br/>
<p>These factors suggest a need for careful documentation and justification to support the R&D claim.</p>

                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10">Baseline Research <BadgeCheckOutline color="green"></BadgeCheckOutline></span>
                    <P><strong>Genuine & non-trivial R&D</strong></P>
                </AccordionItem>
            </Accordion>
            <P size="lg" class="my-5"><Span gradient>Baseline</Span></P>

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10">Comprehensiveness <BadgeCheckOutline color="brown"></BadgeCheckOutline></span>
                    <p><strong>Optional Improvement:</strong></p>
<br/>
<p>The answer covers the baseline level of technology, existing approaches, and their limitations, which is good.</p>
<br/>
<p>However, it lacks details on how the baseline was established (e.g., academic sources or industry references) and does not mention the profiles of the Competent Professionals involved.</p>
<br/>
<p>Including these would make it more comprehensive.</p>

                </AccordionItem>
                <AccordionItem>
                    <span slot="header"class="inline-flex items-center mr-10">Focus <BadgeCheckOutline color="green"></BadgeCheckOutline></span>
                    <P>Focused</P>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header"class="inline-flex items-center mr-10">Phrasing<BadgeCheckOutline color="brown"></BadgeCheckOutline></span>
                    <div class="max-w-full prose">
        {@html marked(`
**Optional Improvement:** To better align with HMRC terminology, explicitly reference the involvement of a Competent Professional and their qualifications in establishing the baseline. For example:

> "In line with GfC3 guidelines, the baseline was established by Competent Professional ABC, who holds [qualifications] and has [X years of experience] in [field]. They are knowledgeable about the relevant scientific and technological principles involved and are aware of the current state of knowledge in healthcare logistics and emergency response, enabling them to identify the limitations of conventional methods and the potential of algorithmic optimization."
        `)}
    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header"class="inline-flex items-center mr-10">Grammar & Other<BadgeCheckOutline color="green"></BadgeCheckOutline></span>
                    <P>No mistake detected</P>
                </AccordionItem>

            </Accordion>

            <P size="lg" class="my-5"><Span gradient>Advance</Span></P>

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10">Comprehensiveness<BadgeCheckOutline color="green"></BadgeCheckOutline>
                    </span>
                    <P>Coming soon</P>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header"class="inline-flex items-center mr-10">Focus<FlagOutline color="red"></FlagOutline></span>
                    <P>Coming soon</P>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header"class="inline-flex items-center mr-10">Phrasing<BadgeCheckOutline color="brown"></BadgeCheckOutline></span>
                    <P>Coming soon</P>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header"class="inline-flex items-center mr-10">Grammar & Other<BadgeCheckOutline color="green"></BadgeCheckOutline></span>
                    <P>Coming soon</P>
                </AccordionItem>
            </Accordion>

            <P size="lg" class="my-5"><Span gradient>Uncertainty</Span></P>

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header"class="inline-flex items-center mr-10">Comprehensiveness<BadgeCheckOutline color="green"></BadgeCheckOutline>
                    </span>
                    <P>Coming soon</P>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header"class="inline-flex items-center mr-10">Focus<BadgeCheckOutline color="green"></BadgeCheckOutline></span>
                    <P>Coming soon</P>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header"class="inline-flex items-center mr-10">Phrasing<BadgeCheckOutline color="green"></BadgeCheckOutline></span>
                    <P>Coming soon</P>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header"class="inline-flex items-center mr-10">Grammar & Other<BadgeCheckOutline color="green"></BadgeCheckOutline></span>
                    <P>Coming soon</P>
                </AccordionItem>
            </Accordion>


            <P size="lg" class="my-5"><Span gradient>Resolution</Span></P>

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header"class="inline-flex items-center mr-10">Comprehensiveness<BadgeCheckOutline color="green"></BadgeCheckOutline>
                    </span>
                    <P>Coming soon</P>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header"class="inline-flex items-center mr-10">Focus<FlagOutline color="red"></FlagOutline></span>
                    <P>Coming soon</P>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header"class="inline-flex items-center mr-10">Phrasing<BadgeCheckOutline color="green"></BadgeCheckOutline></span>
                    <P>Coming soon</P>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header"class="inline-flex items-center mr-10">Grammar & Other<BadgeCheckOutline color="green"></BadgeCheckOutline></span>
                    <P>Coming soon</P>
                </AccordionItem>
            </Accordion>
        </Card>
    </div>
</div>
