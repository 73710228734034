<script>
    import {
        Table,
        TableHead,
        TableHeadCell,
        TableBody,
        TableBodyRow,
        TableBodyCell,
        Button,
        Spinner,
        Modal,
        P,
    } from "flowbite-svelte";
    import { EyeSolid, TrashBinSolid } from "flowbite-svelte-icons";
    import { files_list, selected_file_id } from "../service/store";
    import { deleteFile, extract, fetchFiles } from "../service/http_calls";
    import { toast } from "@zerodevx/svelte-toast";

    const handleDeleteFile = async (file_id) => {
        console.log("deleting " + file_id);
        await deleteFile(file_id);
        let resp = await fetchFiles(module);
        files_list.set(resp);
        
    };
    export let module;
    let loading = false;
    let file_text_edit = false;


    const pollProgress = async () => {
        const interval = setInterval(async () => {
            const resp =await fetchFiles(module);
            files_list.set(resp);

            if (resp.every((f) => f["vectorization_progress"] >= 99)) {
                clearInterval(interval);
            } else {
                console.log("Still polling...");
            }
        }, 5000);
    };
    let selectedFileName = $files_list.find(
        (obj) => obj.id == $selected_file_id,
    )?.name;

    // Whenever the file selection or file list changes, update the local variable
    $: selectedFileName = $files_list.find(
        (obj) => obj.id == $selected_file_id,
    )?.name;

    async function handleFileChange(event) {
        loading = true;
        try {
            const resp = await extract(event.target.files, module);
            files_list.set(resp);
            pollProgress();
        } catch (error) {
            console.error("Error uploading file:", error);
            toast.push(error.message); // Set the error message
        } finally {
            loading = false;
        }
    }
</script>

<div class="mt-5">
    <label
        class="block mb-2 text-sm font-medium text-gray-700 mt-5"
        for="docuemtnUpload">Add file(s)</label
    >
    <input
        id="docuemtnUpload"
        type="file"
        accept=".pdf,.mp4"
        multiple
        class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
        on:change={handleFileChange}
    />
    <Table striped={true} class="w-full table-fixed mt-5">
        <TableHead
            class="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400"
        >
            <TableHeadCell>File name</TableHeadCell>
            <TableHeadCell class="w-1/6  text-right">Status</TableHeadCell>
            <TableHeadCell class="w-1/6  text-right">Content</TableHeadCell>
            <TableHeadCell class="w-1/6 text-right">Delete</TableHeadCell>
        </TableHead>
        <TableBody tableBodyClass="divide-y">
            {#each $files_list as file}
                <TableBodyRow>
                    <TableBodyCell
                        class="truncate overflow-hidden text-ellipsis"
                        >{file.name}</TableBodyCell
                    >
                    <TableBodyCell class="text-right pr-10"
                        >{file.status}</TableBodyCell
                    >
                    <TableBodyCell class="text-right pr-10">
                        <Button
                            outline={true}
                            class="!p-2"
                            size="xs"
                            color="purple"
                            on:click={() => {
                                selected_file_id.set(file.id);
                                file_text_edit = true;
                            }}
                        >
                            <EyeSolid class="" />
                        </Button>
                    </TableBodyCell>
                    <TableBodyCell class="text-right">
                        <Button
                            outline={true}
                            class="!p-2"
                            size="xs"
                            color="red"
                            on:click={() => {
                                handleDeleteFile(file.id);
                            }}
                        >
                            <TrashBinSolid class="" />
                        </Button>
                    </TableBodyCell>
                </TableBodyRow>
            {/each}
        </TableBody>
    </Table>
</div>

<Modal bind:title={selectedFileName} bind:open={file_text_edit} autoclose>
    <P>{$files_list.find((obj) => obj.id == $selected_file_id)?.content}</P>
</Modal>
