<script>
    import { P } from "flowbite-svelte";
    import { fetchCredit, logoutWithKratos } from "../service/http_calls";
    import { authenticated_user } from "../service/store";
    import { onMount } from "svelte";

    export let credit = 100; // Example credit value, you can replace it with dynamic data

    async function handleLogout() {
        const logout_url = await logoutWithKratos()
        window.location.href = logout_url;        
    }
    onMount(async ()=>{
        credit = (await fetchCredit()).credit;
    });
</script>

<!-- Card Container -->
<div class="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-50 to-blue-100">
    <div class="max-w-sm w-full p-6 bg-white shadow-xl rounded-lg transform transition duration-500 hover:scale-105">
        <P Heading class="text-center text-2xl font-bold text-gray-800 mb-6">User Info</P>
        
        <!-- User Info Table -->
        <div class="overflow-hidden rounded-lg">
            <table class="min-w-full table-auto bg-gray-100 rounded-lg">
                <tbody class="text-gray-600 text-sm font-medium">
                    <tr class="border-b border-gray-200">
                        <td class="px-4 py-3 font-semibold text-gray-700">Email:</td>
                        <td class="px-4 py-3">{$authenticated_user.identity.traits.email}</td>
                    </tr>
                    <tr class="border-b border-gray-200">
                        <td class="px-4 py-3 font-semibold text-gray-700">First Name:</td>
                        <td class="px-4 py-3">{$authenticated_user.identity.traits.name.first}</td>
                    </tr>
                    <tr class="border-b border-gray-200">
                        <td class="px-4 py-3 font-semibold text-gray-700">Last Name:</td>
                        <td class="px-4 py-3">{$authenticated_user.identity.traits.name.last}</td>
                    </tr>
                    <tr>
                        <td class="px-4 py-3 font-semibold text-gray-700">Credit:</td>
                        <td class="px-4 py-3 text-blue-600 font-bold text-lg">{credit} calls left</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Logout Button -->
        <div class="flex justify-center mt-6">
            <button
                on:click={handleLogout}
                class="flex items-center px-5 py-3 bg-red-500 text-white rounded-full hover:bg-red-600 focus:ring-4 focus:ring-red-300 transition transform duration-300 hover:scale-105">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2">
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6-4v8" />
                </svg>
                Logout
            </button>
        </div>
    </div>
</div>
