import { toast } from "@zerodevx/svelte-toast";
import { data } from "autoprefixer";

const BASE_URL = import.meta.env.VITE_BASE_URL;
const KRATOS_BASE_URL = import.meta.env.VITE_KRATOS_BASE_URL;

async function fetchFromApi(endpoint, options = {}) {
    const response = await fetch(`${BASE_URL}${endpoint}`, options);
    if (!response.ok) {
        const cont = await response.text();
        toast.push(cont);
    }
    return await response.json();
}

async function fetchFromKratos(endpoint, options = {}) {
    const response = await fetch(`${KRATOS_BASE_URL}${endpoint}`, options);
    if (response.status == 401) {
        return null;
    }
    if (!response.ok) {
        return null;
    }
    return await response.json();
}
export async function fetchCompanyInfo(company) {
    return await fetchFromApi(`/search/${company}`);
}

export async function assessInnovation(details) {
    return await fetchFromApi(`/assess`, {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-control-allow-credentials': 'true',
            'vary': 'Origin'
        },
        body: JSON.stringify({
            details,
        })
    });
}



export async function earlyAccess(entry) {
    return await fetchFromApi(`/early_access`, {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-control-allow-credentials': 'true',
            'vary': 'Origin'
        },
        body: JSON.stringify({
            name: entry.name,
            email: entry.email,
            company_name: entry.company_name
        })
    });
}
export async function extract(files, module) {
    const formData = new FormData();
    Array.from(files).forEach(file => {
        formData.append("files", file);
    });
    return await fetchFromApi('/extract?' + new URLSearchParams({module: module}).toString(), {
        method: 'POST',
        body: formData,
    });
}



export async function process(general_prompt, special_prompts, input_type) {
    return await fetchFromApi(`/process`, {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-control-allow-credentials': 'true',
            'vary': 'Origin'
        },
        body: JSON.stringify({
            general_prompt,
            special_prompts,
            input_type
        })
    });
}



export async function askAssistant(general_prompt, special_prompts, input_type) {
    return await fetchFromApi(`/ask_assistant`, {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-control-allow-credentials': 'true',
            'vary': 'Origin'
        },
        body: JSON.stringify({
            general_prompt,
            special_prompts,
            input_type
        })
    });
}


export async function fetchFiles(module) {
    return await fetchFromApi('/files?' + new URLSearchParams({module: module}).toString(), {
        method: 'GET',
    });
}

export async function deleteFile(file_id) {
    return await fetchFromApi(`/files/${file_id}`, {
        method: 'DELETE',
    });
}

export async function getFileVectorizationProgress(file_id) {
    return await fetchFromApi(`/files/${file_id}/vectorization_progress`, {
        method: 'GET',
    });
}



export async function getSourceAttributes(content) {
    return await fetchFromApi(`/source_attributes`, {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            content
        })
    });
}
export async function getHallucinations(content) {
    return await fetchFromApi(`/find_discrepancies`, {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            content
        })
    });
}

export async function fetchMe() {
    return await fetchFromKratos(`/sessions/whoami`, {
        method: 'GET',
        credentials: 'include',
    });
}
export async function fetchCredit() {
    return await fetchFromApi(`/me`, {
        method: 'GET',
        credentials: 'include',
    });
}

export async function getUser() {
    return await fetchFromApi(`/me`, {
        method: 'GET',
        credentials: 'include',
    });
}

export async function logoutWithKratos() {
    const resp = await fetchFromKratos(`/self-service/logout/browser`, {
        method: 'GET',
        credentials: 'include',
    });
    return resp.logout_url;
}