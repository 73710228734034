import { writable } from 'svelte/store';


function createLocalStorageStore(key, initialValue) {
    let storedValue = localStorage.getItem(key);
    // console.log("Stored value for " + key + " is " + storedValue);

    if (storedValue === null || storedValue === undefined) {
        storedValue = initialValue;
    } else {
        try {
            storedValue = JSON.parse(storedValue);
        } catch (e) {
            console.error("Error parsing stored value for " + key, e);
            storedValue = initialValue;
        }
    }

    const store = writable(storedValue);

    store.subscribe(value => {
        localStorage.setItem(key, JSON.stringify(value));
    });

    return store;
}

export const sources = createLocalStorageStore("sources", "");
export const hallucination = createLocalStorageStore("hallucination", "");


export const prompt_edit_open = writable(false);
export const discrepancy_modal_open = writable(false);
export const source_sentence_modal_open = writable(false);
export const source_sentence_questions_modal_open = writable(false);
export const discrepancy_modal_questions_open = writable(false);
export const ajust_response_modal_open = writable(false);



export const authenticated_user = createLocalStorageStore("authenticated_user", null);
export const current_user = createLocalStorageStore("current_user", null);


export const question_prompt_edit_open = writable(false);
export const files_list = createLocalStorageStore('files_list', []);
export const selected_file_id = createLocalStorageStore('selected_file_id', 0);

export const eligbility_info = createLocalStorageStore("eligbility_info", { companies: [], selected_company: 0, activity_details: "", innovation_strength: 0, assessment_details: "", });

export const selected_component = createLocalStorageStore('selected_component', "general");
export const selected_question = createLocalStorageStore('selected_question', "");
export const general_prompt = createLocalStorageStore("general_prompt", ``);


export const components = createLocalStorageStore('components', {
    "general": {
        "prompt": ``,
        "response": [],
        "title": "General"
    },
    "context": {
        "prompt": ``,
        "response": [],

        "title": "Context, Objective, Problem"
    },
    "baseline": {
        "prompt": ``,
        "response": [],
        "title": "Baseline"
    },
    "establishing_baseline": {
        "prompt": ``,
        "response": [],
        "title": "Establishing Baseline"
    },
    "competent_professionals": {
        "prompt": ``,
        "response": [],

        "title": "Competent Professionals"

    },
    "advance": {
        "prompt": ``,
        "response": [],
        "title": "Advance"

    },
    "uncertainty_1": {
        "prompt": ``,
        "response": [],

        "title": "Uncertainty: not publicly available"
    },
    "uncertainty_2": {
        "prompt": ``,
        "response": [],
        "title": "Uncertainty: not readily deducible"

    },
    "how": {
        "prompt": ``,
        "response": [],

        "title": "How?"
    }
});



export const questions = createLocalStorageStore('questions', {

    "q_1": {
        "response": [],
        "title": "Enter a name for the project",
        "prompt": ``
    },
    "q_2": {
        "response": [],
        "title": "What is the main field of science or technology?",
        "prompt": ``
    },
    "q_3": {
        "response": [],
        "title": "What is the baseline level of science or technology?",
        "prompt": ``
    },
    "q_4": {
        "response": [],
        "title": "What is the scientific or technological advance sought?",
        "prompt": ``
    },
    "q_5": {
        "response": [],
        "title": "What scientific or technical uncertainties did you face?",
        "prompt": ``
    },
    "q_6": {
        "response": [],
        "title": "How did the project seek to overcome these uncertainties?",
        "prompt": ``
    }


});