<script>
    import {
        Accordion,
        AccordionItem,
        Button,
        Card,
        P,
        Span,
        Spinner,
        Textarea
    } from "flowbite-svelte";
    import { marked } from "marked";
    import { onMount } from "svelte";
    import {
        fetchFiles,
        fetchMe,
        getUser
    } from "../service/http_calls";
    import {
        authenticated_user,
        current_user,
        files_list
    } from "../service/store.js";
    import FilesTable from "./FilesTable.svelte";
    const KUI_BASE_URL = import.meta.env.VITE_KUI_BASE_URL;
    let loading_ingest = false;


    onMount(async () => {
        let auth_resp = await fetchMe();
        if (!auth_resp) {
            window.location.href = KUI_BASE_URL + "/login";
        } else {
            authenticated_user.set(auth_resp);

            let user = await getUser();
            current_user.set(user);
        }
        const resp = await fetchFiles("research");
        files_list.set(resp);
    });
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <FilesTable module="research"></FilesTable>
            <div class="flex justify-end my-5">
                {#if loading_ingest}
                    <Spinner></Spinner>
                {:else}
                    <Button size="sm" color="purple">Submit</Button>
                {/if}
            </div>
            <P size="lg" class="my-5"><Span gradient>Research Input</Span></P>
            <Textarea rows="2"></Textarea>
            <div class="flex justify-end my-5">
                <Button size="sm" color="purple">Submit</Button>
            </div>
            <P size="lg" class="my-5"><Span gradient>Research Output</Span></P>

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header">Background</span>
                    <div class="max-w-full prose">

                    {@html marked(`
A genetic algorithm is a computational method inspired by natural evolution, used to solve optimization and search problems. It mimics the process of natural selection where the fittest individuals are chosen to reproduce and create the next generation. By iteratively selecting, crossing over, and mutating a population of potential solutions, genetic algorithms evolve better solutions over time.

A Mobile Stroke Unit is a specialized ambulance equipped with imaging technology and stroke treatment capabilities, enabling rapid diagnosis and immediate care for stroke patients before reaching the hospital.
                    `)}
</div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Academic Papers</span>
                    <div class="max-w-full prose">

                    {@html marked(`
The deployment of mobile stroke units (MSUs) is a critical aspect of improving acute stroke care, and optimizing their deployment through genetic algorithms (GAs) represents an innovative approach. This response synthesizes relevant literature on genetic algorithms, methods for optimizing the deployment of mobile stroke units, and the application of genetic algorithms specifically for MSU deployment.

1. **Knowledge on Genetic Algorithms**: Genetic algorithms are a class of optimization techniques inspired by the process of natural selection. They are widely used in various fields for solving complex optimization problems. For instance, the article "Genetic algorithms in search, optimization, and machine learning" provides foundational knowledge on how GAs function, emphasizing their effectiveness in finding optimal solutions in large search spaces ("Genetic algorithms in search, optimization, and machine learning", 1989). Additionally, discuss the application of GAs in node deployment for hybrid wireless sensor networks, highlighting their capability to achieve optimal or near-optimal solutions for complex deployment scenarios (Banimelhem et al., 2013). This foundational understanding of GAs is crucial for applying them to the deployment of MSUs.

2. **Methods of Optimizing Deployment of Mobile Stroke Units**: The literature on optimizing the deployment of emergency medical services, including MSUs, emphasizes the importance of timely response and resource allocation. For example, the study by discusses the significance of accurate stroke recognition and dispatch protocols, which are essential for the effective deployment of MSUs (Mattila et al., 2019). Furthermore, highlight the advancements in prehospital stroke care, including the integration of MSUs equipped with diagnostic tools, which necessitates optimized deployment strategies to enhance treatment outcomes (Ebinger & Audebert, 2021). These studies underscore the need for systematic approaches to optimize the deployment of MSUs in various operational contexts.

3. **Knowledge on Using Genetic Algorithms for Deployment of Mobile Stroke Units**: While specific studies directly linking GAs to MSU deployment are limited, the principles established in related fields can be adapted. For instance, the work by on multi-objective node deployment in mobile underwater wireless sensor networks illustrates how GAs can effectively address complex deployment challenges, which can be analogous to the deployment of MSUs in urban environments (Fattah et al., 2022). Additionally, the research by on wireless sensor node placement using hybrid genetic programming and GAs demonstrates the versatility of GAs in optimizing deployment strategies across different contexts (Tripathi et al., 2011). These findings suggest that GAs can be effectively utilized to optimize the deployment of MSUs by considering factors such as coverage, response time, and resource allocation.

In conclusion, while there is a wealth of knowledge on genetic algorithms and their application in various optimization scenarios, the specific application of GAs for optimizing the deployment of mobile stroke units appears to be an innovative area with limited existing literature. This presents an opportunity for further research and development, leveraging the principles of GAs to enhance the efficiency and effectiveness of MSU deployment.

**Sources:**

- (1989). Genetic algorithms in search, optimization, and machine learning. *Choice Reviews Online*, 27(02), 27-0936-27-0936. [https://doi.org/10.5860/choice.27-0936](https://doi.org/10.5860/choice.27-0936)

- Banimelhem, O., Mowafi, M., & Aljoby, W. (2013). Genetic algorithm based node deployment in hybrid wireless sensor networks. *Communications and Network*, 05(04), 273-279. [https://doi.org/10.4236/cn.2013.54034](https://doi.org/10.4236/cn.2013.54034)

- Mattila, O., Puolakka, T., Ritvonen, J., Pihlasviita, S., Harve, H., Alanen, A., … & Lindsberg, P. (2019). Targets for improving dispatcher identification of acute stroke. *International Journal of Stroke*, 14(4), 409-416. [https://doi.org/10.1177/1747493019830315](https://doi.org/10.1177/1747493019830315)

- Ebinger, M. and Audebert, H. (2021). Shifting acute stroke management to the prehospital setting. *Current Opinion in Neurology*, 35(1), 4-9. [https://doi.org/10.1097/wco.0000000000001012](https://doi.org/10.1097/wco.0000000000001012)

- Fattah, S., Ahmedy, I., Idris, M., & Gani, A. (2022). Hybrid multi-objective node deployment for energy-coverage problem in mobile underwater wireless sensor networks. *International Journal of Distributed Sensor Networks*, 18(9), 155013292211235. [https://doi.org/10.1177/15501329221123533](https://doi.org/10.1177/15501329221123533)

- Tripathi, A., Gupta, P., Trivedi, A., & Kala, R. (2011). Wireless sensor node placement using hybrid genetic programming and genetic algorithms. *International Journal of Intelligent Information Technologies*, 7(2), 63-83. [https://doi.org/10.4018/jiit.2011040104](https://doi.org/10.4018/jiit.2011040104)
                    `)}
</div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Internet Search</span>
                    <div class="max-w-full prose">
{@html marked(`
Based on the search results and available information, here's an assessment of the innovation and existing knowledge regarding using genetic algorithms to optimize mobile stroke unit (MSU) deployment:

**Knowledge on Genetic Algorithms**: Genetic algorithms are a well-established optimization technique. Several sources mention their use in healthcare and ambulance deployment:

- Comber et al. used a modified grouping genetic algorithm to select ambulance site locations.
- Huang et al. used a genetic algorithm with a new stirring operator to optimize automated external defibrillator locations.
- Gupta et al. used genetic algorithms for optimizing ambulance deployment.

These sources indicate that genetic algorithms are known and used in healthcare logistics optimization.

**Methods of Optimizing MSU Deployment**: Some existing methods for optimizing MSU deployment include:

- Dahllöf et al. used a method considering equality and efficiency perspectives.
- Phan et al. used geospatial analysis to optimize MSU base locations in Sydney.
- Rhudy et al. used geospatial visualization of MSU dispatches to optimize service performance.

These show that various methods have been applied to MSU deployment optimization, though they may not specifically use genetic algorithms.

**Using Genetic Algorithms for MSU Deployment**:

The most relevant source is Abid et al.'s study "A Genetic Algorithm for Optimizing Mobile Stroke Unit Deployment". This research:

- Proposes a time-efficient method using a genetic algorithm to find suitable MSU locations.
- Develops custom selection, crossover, and mutation operators for the MSU allocation problem.
- Demonstrates the method's effectiveness in a case study in Sweden.

This source directly addresses using genetic algorithms for MSU deployment optimization.

**Explanation**:

While using genetic algorithms for healthcare logistics optimization is not entirely new, applying them specifically to MSU deployment appears to be relatively recent and innovative. The Abid et al. study from 2023 suggests that this approach is at the forefront of research in this area.

Your work could be considered innovative if you are:

- Developing novel genetic algorithm operators specific to MSU deployment.
- Applying this method to new geographical areas or scenarios.
- Incorporating additional constraints or objectives not considered in previous studies.
- Comparing the genetic algorithm approach to other optimization methods for MSU deployment.

While not entirely unprecedented, your work likely contributes to an emerging and important area of research in stroke care optimization.

**Sources:**

- [Comber et al.](https://ouci.dntb.gov.ua/en/works/7XwwnJM9/)
- [Abid et al. - A Genetic Algorithm for Optimizing Mobile Stroke Unit Deployment](https://portal.research.lu.se/en/publications/a-genetic-algorithm-for-optimizing-mobile-stroke-unit-deployment)

`)}

                        </div>
                </AccordionItem>
            </Accordion>
            <div class="mt-5 flex justify-end"></div>
        </Card>
    </div>
</div>
