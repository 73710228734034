<script>
    import {
        Accordion,
        AccordionItem,
        Button,
        Card,
        P,
        Span,
        Spinner,
        Textarea
    } from "flowbite-svelte";
    import { marked } from "marked";
    import { onMount } from "svelte";
    import {
        fetchFiles,
        fetchMe,
        getUser
    } from "../service/http_calls";
    import {
        authenticated_user,
        current_user,
        files_list
    } from "../service/store.js";
    import FilesTable from "./FilesTable.svelte";
    const KUI_BASE_URL = import.meta.env.VITE_KUI_BASE_URL;
    let loading_ingest = false;


    onMount(async () => {
        let auth_resp = await fetchMe();
        if (!auth_resp) {
            window.location.href = KUI_BASE_URL + "/login";
        } else {
            authenticated_user.set(auth_resp);

            let user = await getUser();
            current_user.set(user);
        }
        const resp = await fetchFiles("defend");
        files_list.set(resp);
    });
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <FilesTable module="defend"></FilesTable>
            <div class="flex justify-end my-5">
                {#if loading_ingest}
                    <Spinner></Spinner>
                {:else}
                    <Button size="sm" color="purple">Submit</Button>
                {/if}
            </div>
            <P size="lg" class="my-5"><Span gradient>Instructions</Span></P>
            <Textarea rows="2"></Textarea>
            <div class="flex justify-end my-5">
                <Button size="sm" color="purple">Submit</Button>
            </div>
            <P size="lg" class="my-5"><Span gradient>Output</Span></P>

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header">Enquiry Assessment</span>
                    <div class="max-w-full prose">

                    {@html marked(` `)}
</div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Enquiry Response Draft</span>
                    <div class="max-w-full prose">

                    {@html marked(` `)}
</div>
                </AccordionItem>
            </Accordion>
            <div class="mt-5 flex justify-end"></div>
        </Card>
    </div>
</div>
