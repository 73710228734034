<script>
    import { AccordionItem, Button, Modal, Spinner } from "flowbite-svelte";
    import { marked } from "marked";
    import {
        ajust_response_modal_open,
        components,
        current_user,
        discrepancy_modal_open,
        discrepancy_modal_questions_open,
        general_prompt,
        hallucination,
        question_prompt_edit_open,
        questions,
        selected_question,
        source_sentence_questions_modal_open,
        sources,
    } from "../service/store";
    import {
        AdjustmentsHorizontalOutline,
        FileCopyOutline,
    } from "flowbite-svelte-icons";
    import {
        getHallucinations,
        getSourceAttributes,
        process,
    } from "../service/http_calls";
    import { toast } from "@zerodevx/svelte-toast";

    export let question;
    export let title;
    export let hideAdjustment = false;
    let loading = false;
    let loading_discrepancies = false;
    let selected_paragraph = "";
    async function handleCompile(question) {
        loading = true;
        let all_components = Object.keys($components)
            .map((key) =>
                $components[key].response
                    .map((item) => item.response)
                    .join(" "),
            )
            .join(" ");

        let resp = await process(
            $general_prompt,
            {
                [question]: $questions[question].prompt,
            },
            "questions",
        );
        questions.update((prev) => {
            prev[question].response = resp[question].response;
            return prev;
        });
        loading = false;
        toast.push("Regenerated successfully");
    }
    async function handleViewDiscrepancy() {
        loading_discrepancies = true;
        const resp = await getHallucinations(
            $questions[question].response
                .map((item) => item.response)
                .join(" "),
        );
        hallucination.set(resp["hallucination"]);
        loading_discrepancies = false;
        discrepancy_modal_questions_open.set(true);
    }
</script>

<AccordionItem>
    <span slot="header">{title}</span>
    <!-- <div
        class="prose max-w-full hover:prose-p:underline hover:prose-p:decoration-blue-500 hover:prose-p:decoration-2 hover:prose-ul:underline hover:prose-ul:decoration-blue-500 hover:prose-ul:decoration-2"
    > -->
    <div class="prose max-w-full">
        {#each $questions[question]?.response as item, index}
            <div
                on:click={() => {
                    console.log("Clicked on " + item);
                    if ($current_user?.user_type === "admin") {
                        source_sentence_questions_modal_open.set(true);
                        sources.set(
                            $questions[question]?.response[index].source,
                        );
                    }
                }}
            >
                {@html marked(item.response)}
            </div>
        {/each}
    </div>
    <div class="flex justify-between mt-5">
        <div class="flex justify-start">
            <button
                on:click={() => {
                    navigator.clipboard.writeText(
                        $questions[question].response
                            .map((item) => item.response)
                            .join(" "),
                    );
                }}
                style="background: none; border: none; cursor: pointer;"
                class="mr-5"
            >
                <FileCopyOutline></FileCopyOutline>
            </button>
            <!-- {#if loading_discrepancies}
                <Spinner></Spinner>
            {:else}
                <Button
                    size="xs"
                    color="alternative"
                    class="mr-5"
                    on:click={() => {
                        selected_question.set(question);
                        handleViewDiscrepancy();
                    }}>Find Discrepancies</Button
                >
            {/if} -->
        </div>

        <div class="flex justify-end">
            {#if !hideAdjustment && $current_user?.user_type === "admin"}
                <button
                    on:click={() => {
                        ajust_response_modal_open.set(true);
                    }}
                    style="background: none; border: none; cursor: pointer;"
                    class="mr-5"
                >
                    <AdjustmentsHorizontalOutline
                    ></AdjustmentsHorizontalOutline>
                </button>
            {/if}

            <Button
                size="xs"
                color="alternative"
                class="mr-5"
                on:click={() => {
                    question_prompt_edit_open.set(true);
                    selected_question.set(question);
                }}>Add Instructions</Button
            >
            {#if loading}
                <Spinner></Spinner>
            {:else}
                <Button
                    size="xs"
                    color="purple"
                    on:click={() => {
                        handleCompile(question);
                    }}>Regenerate</Button
                >
            {/if}
        </div>
    </div>
</AccordionItem>
