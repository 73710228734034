<script>
    import {
        Accordion,
        AccordionItem,
        Button,
        Card,
        P,
        Span,
        Textarea,
    } from "flowbite-svelte";
    import { onMount } from "svelte";
    import { fetchFiles, fetchMe, getUser } from "../service/http_calls";
    import {
        authenticated_user,
        current_user,
        files_list,
    } from "../service/store.js";
    import FilesTable from "./FilesTable.svelte";
    import Speedometer from "svelte-speedometer";
    import { marked } from "marked";

    const KUI_BASE_URL = import.meta.env.VITE_KUI_BASE_URL;
    let options = {
        thickness: 12,
        legend: "Total",
        color: "orangered",
        bgColor: "#bbb",
        valueOverflow: true,
        textColor: "#ffc0b5",
    };
    onMount(async () => {
        let auth_resp = await fetchMe();
        if (!auth_resp) {
            window.location.href = KUI_BASE_URL + "/login";
        } else {
            authenticated_user.set(auth_resp);

            let user = await getUser();
            current_user.set(user);
        }
        const resp = await fetchFiles("qualify");
        files_list.set(resp);
    });
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <FilesTable module="qualify"></FilesTable>
            <div class="flex justify-end my-5">
                <Button size="sm" color="purple">Submit</Button>
            </div>

            <P size="lg" class="my-5"><Span gradient>Overall</Span></P>
            <!-- <div class="flex justify-center">
                <Speedometer minValue={0} maxValue={100} value={75}
                ></Speedometer>
            </div> -->
            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header">Summary</span>
                    <div class="max-w-full prose">
                        {@html marked(`
**1. Context**

The narrative addresses the urgent need for optimizing the placement of Mobile Stroke Units (MSUs) to ensure rapid treatment of stroke patients. Stroke is a critical medical condition requiring immediate intervention, and MSUs offer on-site diagnosis and treatment. However, due to high operational costs, only a limited number of MSUs can be deployed, making their strategic placement crucial.

**2. Baseline**

The current standard for treating stroke patients involves the use of MSUs, which are specialized ambulances equipped with CT scanners. These units can diagnose and begin treatment while the patient is still en route to the hospital. However, determining the optimal locations for MSUs to maximize their effectiveness is challenging. Existing methods, like exhaustive search (ES), though straightforward, are computationally intensive and become impractical as the search space increases.

**3. Establishing the Baseline**


Previous research has established the effectiveness of genetic algorithms (GA) in solving various optimization problems, including those related to ambulance dispatching. Some studies have explored optimal MSU locations in specific regions, but the exponential growth of the search space when using ES makes it infeasible for larger datasets. This necessitates the development of a more efficient method to determine MSU placement.

**4. Competent Professionals**


The project involved key experts:

- **Muhammad Adil Abid:** Lead Researcher, expert in genetic algorithms and optimization problems, particularly in healthcare applications.
- **Saeid Amouzad Mahdiraji:** Researcher with extensive experience in optimization models for emergency medical services.
- **Fabian Lorig:** Researcher specializing in Internet of Things and optimization methods.
- **Jesper Petersson:** Medical expert from Lund University, specializing in stroke treatment and neurology, providing essential medical insights for the project.

**5. Advance**

The project aimed to develop a time-efficient method using a genetic algorithm (GA) to optimize the placement of MSUs. The team designed a custom encoding scheme for input data and developed specialized selection, crossover, and mutation operators tailored to the specific challenges of MSU allocation. The new method significantly outperformed traditional exhaustive search methods, reducing computation time by up to 24.77 times for larger MSU deployment scenarios.

**6. Uncertainty**

The main uncertainty lay in the limitations of existing methods, such as exhaustive search, which become computationally unfeasible as the number of potential MSU locations increases. The challenge was to create a method that could efficiently search through large, complex datasets without being bogged down by time-consuming calculations.

**7. Resolution**

The project successfully resolved these uncertainties by developing a robust genetic algorithm tailored to the problem of MSU placement. The method was validated through multiple iterations, demonstrating its efficiency and reliability. The approach not only provided faster solutions but also maintained accuracy, making it a practical tool for optimizing MSU deployment in real-world scenarios.`)}
                    </div>
                </AccordionItem>
               
                <AccordionItem>
                    <span slot="header">Research</span>
                    <div class="max-w-full prose">
                        {@html marked(`
The deployment of mobile stroke units (MSUs) is a critical aspect of improving acute stroke care, and optimizing their deployment through genetic algorithms (GAs) represents an innovative approach. This response synthesizes relevant literature on genetic algorithms, methods for optimizing the deployment of mobile stroke units, and the application of genetic algorithms specifically for MSU deployment.

**1. Knowledge on Genetic Algorithms:**  
Genetic algorithms are a class of optimization techniques inspired by the process of natural selection. They are widely used in various fields for solving complex optimization problems. For instance, the article *"Genetic algorithms in search, optimization, and machine learning"* provides foundational knowledge on how GAs function, emphasizing their effectiveness in finding optimal solutions in large search spaces (*"Genetic algorithms in search, optimization, and machine learning,"* 1989). Additionally, discuss the application of GAs in node deployment for hybrid wireless sensor networks, highlighting their capability to achieve optimal or near-optimal solutions for complex deployment scenarios (Banimelhem et al., 2013). This foundational understanding of GAs is crucial for applying them to the deployment of MSUs.

**2. Methods of Optimizing Deployment of Mobile Stroke Units:**  
The literature on optimizing the deployment of emergency medical services, including MSUs, emphasizes the importance of timely response and resource allocation. For example, the study by Mattila et al. (2019) discusses the significance of accurate stroke recognition and dispatch protocols, which are essential for the effective deployment of MSUs. Furthermore, highlight the advancements in prehospital stroke care, including the integration of MSUs equipped with diagnostic tools, which necessitates optimized deployment strategies to enhance treatment outcomes (Ebinger & Audebert, 2021). These studies underscore the need for systematic approaches to optimize the deployment of MSUs in various operational contexts.

**3. Knowledge on Using Genetic Algorithms for Deployment of Mobile Stroke Units:**  
While specific studies directly linking GAs to MSU deployment are limited, the principles established in related fields can be adapted. For instance, the work by Fattah et al. (2022) on multi-objective node deployment in mobile underwater wireless sensor networks illustrates how GAs can effectively address complex deployment challenges, which can be analogous to the deployment of MSUs in urban environments. Additionally, the research by Tripathi et al. (2011) on wireless sensor node placement using hybrid genetic programming and GAs demonstrates the versatility of GAs in optimizing deployment strategies across different contexts. These findings suggest that GAs can be effectively utilized to optimize the deployment of MSUs by considering factors such as coverage, response time, and resource allocation.

In conclusion, while there is a wealth of knowledge on genetic algorithms and their application in various optimization scenarios, the specific application of GAs for optimizing the deployment of mobile stroke units appears to be an innovative area with limited existing literature. This presents an opportunity for further research and development, leveraging the principles of GAs to enhance the efficiency and effectiveness of MSU deployment.

**Sources:**

- *(1989). Genetic algorithms in search, optimization, and machine learning. Choice Reviews Online, 27(02), 27-0936-27-0936.* [https://doi.org/10.5860/choice.27-0936](https://doi.org/10.5860/choice.27-0936)
- *Banimelhem, O., Mowafi, M., & Aljoby, W. (2013). Genetic algorithm based node deployment in hybrid wireless sensor networks. Communications and Network, 05(04), 273-279.* [https://doi.org/10.4236/cn.2013.54034](https://doi.org/10.4236/cn.2013.54034)
- *Mattila, O., Puolakka, T., Ritvonen, J., Pihlasviita, S., Harve, H., Alanen, A., … & Lindsberg, P. (2019). Targets for improving dispatcher identification of acute stroke. International Journal of Stroke, 14(4), 409-416.* [https://doi.org/10.1177/1747493019830315](https://doi.org/10.1177/1747493019830315)
- *Ebinger, M. and Audebert, H. (2021). Shifting acute stroke management to the prehospital setting. Current Opinion in Neurology, 35(1), 4-9.* [https://doi.org/10.1097/wco.0000000000001012](https://doi.org/10.1097/wco.0000000000001012)
- *Fattah, S., Ahmedy, I., Idris, M., & Gani, A. (2022). Hybrid multi-objective node deployment for energy-coverage problem in mobile underwater wireless sensor networks. International Journal of Distributed Sensor Networks, 18(9), 155013292211235.* [https://doi.org/10.1177/15501329221123533](https://doi.org/10.1177/15501329221123533)
- *Tripathi, A., Gupta, P., Trivedi, A., & Kala, R. (2011). Wireless sensor node placement using hybrid genetic programming and genetic algorithms. International Journal of Intelligent Information Technologies, 7(2), 63-83.* [https://doi.org/10.4018/jiit.2011040104](https://doi.org/10.4018/jiit.2011040104)

Based on the search results and available information, here's an assessment of the innovation and existing knowledge regarding using genetic algorithms to optimize mobile stroke unit (MSU) deployment:

**Knowledge on genetic algorithms:**  
Genetic algorithms are a well-established optimization technique. Several sources mention their use in healthcare and ambulance deployment:

- Comber et al. used a modified grouping genetic algorithm to select ambulance site locations.
- Huang et al. used a genetic algorithm with a new stirring operator to optimize automated external defibrillator locations.
- Gupta et al. used genetic algorithms for optimizing ambulance deployment.

These sources indicate that genetic algorithms are known and used in healthcare logistics optimization.

**Methods of optimizing MSU deployment:**  
Some existing methods for optimizing MSU deployment include:

- Dahllöf et al. used a method considering equality and efficiency perspectives.
- Phan et al. used geospatial analysis to optimize MSU base locations in Sydney.
- Rhudy et al. used geospatial visualization of MSU dispatches to optimize service performance.

These show that various methods have been applied to MSU deployment optimization, though they may not specifically use genetic algorithms.

**Using genetic algorithms for MSU deployment:**  
The most relevant source is Abid et al.'s study "A Genetic Algorithm for Optimizing Mobile Stroke Unit Deployment". This research:

- Proposes a time-efficient method using a genetic algorithm to find suitable MSU locations.
- Develops custom selection, crossover, and mutation operators for the MSU allocation problem.
- Demonstrates the method's effectiveness in a case study in Sweden.

This source directly addresses using genetic algorithms for MSU deployment optimization.

**Explanation:**  
While using genetic algorithms for healthcare logistics optimization is not entirely new, applying them specifically to MSU deployment appears to be relatively recent and innovative. The Abid et al. study from 2023 suggests that this approach is at the forefront of research in this area.

Your work could be considered innovative if you are:

- Developing novel genetic algorithm operators specific to MSU deployment.
- Applying this method to new geographical areas or scenarios.
- Incorporating additional constraints or objectives not considered in previous studies.
- Comparing the genetic algorithm approach to other optimization methods for MSU deployment.

While not entirely unprecedented, your work likely contributes to an emerging and important area of research in stroke care optimization.

**Sources:**

- [https://ouci.dntb.gov.ua/en/works/7XwwnJM9/](https://ouci.dntb.gov.ua/en/works/7XwwnJM9/)
- [https://portal.research.lu.se/en/publications/a-genetic-algorithm-for-optimizing-mobile-stroke-unit-deployment](https://portal.research.lu.se/en/publications/a-genetic-algorithm-for-optimizing-mobile-stroke-unit-deployment)    
    `)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">What could disqualify this case</span>
                    <div class="max-w-full prose">
                        {@html marked(`
                            
Here are some critical hypotheses specific to your case that, if true or untrue, could potentially disqualify the project from UK R&D tax relief. These are phrased as hypothesis statements, making them easy to confirm or reject based on your own knowledge:

**1. The project primarily involved using an existing genetic algorithm with minor adjustments, rather than developing a new or significantly improved algorithm tailored to the specific problem of Mobile Stroke Unit (MSU) placement.**  
- **If true:** This could suggest the work was more about applying known solutions rather than creating new technological advancements, which might affect eligibility for R&D tax relief.

**2. There was a clear and predictable path to achieving the desired outcome with the genetic algorithm, with minimal experimentation or risk of failure involved in optimizing MSU locations.**  
- **If true:** This indicates that the project may not have involved sufficient technical uncertainty, which is necessary to qualify for R&D tax relief.

**3. The project focused on implementing a well-known approach to optimizing healthcare resources, without needing to solve any technical challenges that required innovative problem-solving.**  
- **If true:** This might imply that the project was more about applying existing methods rather than advancing technology, which could disqualify it from R&D tax relief.

**4. The primary challenge of the project was logistical or operational, such as determining the best locations for MSUs based on cost or coverage, rather than addressing a significant technical problem.**  
- **If true:** This suggests that the project may have been more about business strategy than technological innovation, which could impact eligibility for R&D tax relief.

If these hypotheses are true, they could potentially disqualify your project from UK R&D tax relief. You can assess these based on your specific knowledge of the project and the work involved.
                            `)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Follow-up questions for customers</span>
                    <div class="max-w-full prose">
                        {@html marked(`
What specific innovations did your team develop that weren’t available in existing algorithms?

At what stages of the project did you face uncertainty about whether the algorithm would work as intended?

How did you customize the genetic algorithm to address the unique challenges of MSU placement?

Were there any existing solutions that you considered but found inadequate, leading you to create a new approach?

What made the optimization problem for MSU placement complex enough to require a new solution rather than using established methods?
                        `)}
                    </div>
                </AccordionItem>
            </Accordion>
        </Card>
    </div>
</div>
