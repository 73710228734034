<script>
  import { SvelteToast } from "@zerodevx/svelte-toast";
  import { onMount } from "svelte";
  import Drafting from "./lib/Drafting.svelte";
  import EarlyAccess from "./lib/EarlyAccess.svelte";
  import Footer from "./lib/Footer.svelte";
  import Nav from "./lib/Nav.svelte";
  import Profile from "./lib/Profile.svelte";
  import Top from "./lib/Top.svelte";
  import Research from "./lib/Research.svelte";
  import Learn from "./lib/Learn.svelte";
  import Qualify from "./lib/Qualify.svelte";
  import Review from "./lib/Review.svelte";
  import Collaborate from "./lib/Collaborate.svelte";
  import Defend from "./lib/Defend.svelte";
    import Assistant from "./lib/Assistant.svelte";

  let slug = "";
  onMount(async () => {
    // Extracting the slug from the URL
    const pathParts = window.location.pathname.split("/").filter(Boolean);
    if (pathParts.length > 0) {
      slug = pathParts[pathParts.length - 1];
      console.log("You are at " + slug);
    } else {
      console.log("You are home page!");
    }
  });
</script>

<Nav></Nav>
{#if slug === ""}
  <Top></Top>
{:else if slug == "drafting"}
  <Drafting></Drafting>
{:else if slug == "early-access"}
  <EarlyAccess></EarlyAccess>
{:else if slug == "profile"}
  <Profile></Profile>
{:else if slug == "research"}
  <Research></Research>
{:else if slug == "learn"}
  <Learn></Learn>
{:else if slug == "qualify"}
  <Qualify></Qualify>
{:else if slug == "review"}
  <Review></Review>
{:else if slug == "collaborate"}
  <Collaborate></Collaborate>
{:else if slug == "defend"}
  <Defend></Defend>
 {:else if slug == "assistant"}
  <Assistant></Assistant>
{/if}
<SvelteToast />

<Footer></Footer>
